<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="navigation-menu grid-container"
      :class="[
        { 'navigation-menu--open': isOpen },
        { 'navigation-menu--closed': !isOpen },
      ]">
      <slot v-if="loaded"></slot>
    </div>
  </transition>
</template>

<script>
  import EventBus from './../services/event-bus'

  export default {
    name: 'NavigationMenu',
    data() {
      return {
        isOpen: false,
        loaded: true,
        container: null,
      }
    },
    mounted() {
      EventBus.$on('toggleMenu', (isOpen) => {
        this.isOpen = isOpen

        this.$nextTick(() => {
          this.container = document.querySelectorAll(
            '.navigation-menu__inner'
          )[0]

          if (this.container) {
            this.container.style.display = 'flex'
          }
        })
      })

      this.loaded = true
    },
    methods: {
      close() {
        EventBus.$emit('toggleMenu', false)
      },
    },
  }
</script>
