<template>
  <div class="navigation__button-holder">
    <navigation-button></navigation-button>
  </div>
</template>

<script>
  import EventBus from '../services/event-bus'

  export default {
    name: 'NavigationBar',
    data() {
      return {
        nav: null,
        hidden: false,
        ticking: false,
        scrollPos: 0,
        highestScrollPos: 0,
        lowestScrollPos: 0,
      }
    },
    watch: {
      hidden() {
        if (this.nav) {
          this.hideNav(this.hidden)
        } else {
          this.nav = document.querySelector('.navigation-bar')
          this.hideNav(this.hidden)
        }
      },
    },
    mounted() {
      this.nav = document.querySelector('.navigation-bar')
      document.addEventListener('scroll', this.scroll)
      EventBus.$on('scrollContent', (scrollTop) => {
        this.scroll({ scrollTop })
      })
      EventBus.$on('toggleMenu', (isOpen) => {
        this.hideNav(isOpen ? false : this.hidden)
      })
    },
    methods: {
      hideNav(value) {
        if (value) {
          this.nav.classList.add('navigation--hidden')
          this.nav.classList.remove('navigation--visible')
        } else {
          this.nav.classList.add('navigation--visible')
          this.nav.classList.remove('navigation--hidden')
        }
      },
      scroll({ scrollTop }) {
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            const scrollY = scrollTop ? scrollTop - 80 : window.scrollY
            if (scrollY > this.scrollPos) {
              //Scrolling down
              if (scrollY - this.highestScrollPos > 50) {
                //Als de gebruiker naar beneden heeft gescrolled: hide het menu.
                this.hidden = true
              }

              //Reset lowest scroll position
              this.lowestScrollPos = 0
            } else {
              //Scrolling up
              if (scrollY > this.lowestScrollPos) {
                this.lowestScrollPos = scrollY
              }
              if (this.lowestScrollPos - scrollY > 100) {
                //Als de gebruiker 100px naar boven heeft gescrolled: show het menu.
                this.hidden = false
              }

              //Reset highest scroll position
              this.highestScrollPos = 0
              this.highestScrollPos = scrollY
            }
            this.scrollPos = scrollY

            if (scrollY < 50) {
              this.hidden = false
            }

            this.ticking = false
          })
          this.ticking = true
        }
      },
    },
  }
</script>
