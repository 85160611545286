<template>
  <button
    class="navigation__button"
    :class="{
      open: isOpen,
    }"
    @click="open()">
    <div
      class="hamburger"
      :class="{
        open: isOpen,
      }">
      <span v-for="i in 4" :key="`bar-${i}`" class="hamburger__bar"></span>
    </div>
    Menu
  </button>
</template>

<script>
  import EventBus from './../services/event-bus'

  export default {
    name: 'NavigationButton',
    data() {
      return {
        isOpen: false,
      }
    },
    mounted() {
      EventBus.$on('toggleMenu', (isOpen) => {
        this.isOpen = isOpen
        if (isOpen) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'unset'
        }
      })
    },
    methods: {
      open() {
        setTimeout(() => {
          EventBus.$emit('toggleMenu', !this.isOpen)
        }, 100)
      },
    },
  }
</script>
