<template>
  <div class="content-block__content">
    <div class="block__content">
      <div class="search">
        <vSelect
          v-model="evSearch"
          label="name"
          :options="options"
          :clearable="false"
          :placeholder="`${placeholder}?`">
          <!-- Open Options -->
          <template slot="open-indicator">
            <svg
              class="button__toggle"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z"
                fill="black" />
            </svg>
          </template>
          <!-- No Results -->
          <span v-if="noResult" slot="no-options">
            {{ noResult }}
          </span>
        </vSelect>
      </div>
    </div>

    <div class="block__content">
      <!-- Search Results -->
      <evItem v-if="evSearch" :ev-search="evSearch"></evItem>

      <!-- Default state -->
      <div v-else class="results--default">
        <div class="results__header h3">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import evItem from './EvItem.vue'

  export default {
    name: 'FindYourEv',
    components: { vSelect, evItem },
    props: {
      options: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: null,
      },
      noResult: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        result: null,
        evSearch: null,
      }
    },
  }
</script>
