<template>
  <div v-if="b" class="article padding--small">
    <div class="article__image-container">
      <div class="image-container">
        <svg
          class="image-shape"
          viewBox="0 0 647 477"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none">
          <path
            d="M597.241 477L0.165039 388.122L26.5568 291.713L57.723 0L613.687 74.2705L646.165 329.876L597.241 477Z"
            fill="#FBF1BF"></path>
        </svg>

        <picture
          class="image has-bg article__image"
          :data-iesrc="b.image.url"
          :data-alt="b.image.title">
          <img :src="b.image.url" :alt="b.image.title" :title="b.image.title" />
        </picture>
      </div>
    </div>

    <div class="article__content-container">
      <div class="article__content">
        <h3 v-if="b.title" class="article__title h2">{{ b.title }}</h3>
        <div v-if="b.summary" class="article__summary" v-html="b.summary"></div>
        <div v-if="b.cta && b.cta.url" class="article__details">
          <a :href="b.cta.url" target="" class="button button--secondary">{{
            readMoreText
          }}</a>
          <p v-if="b.postDate" class="article__date">{{ b.postDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ArticleItem',
    props: {
      b: {
        type: Object,
        required: true,
      },
      readMoreText: {
        type: String,
        default: 'Load more',
      },
    },
  }
</script>
