<template>
  <button class="splash-button" @click="open()">Open Splash screen</button>
</template>

<script>
  import EventBus from '../services/event-bus'

  export default {
    name: 'SplashButton',
    methods: {
      open() {
        EventBus.$emit('toggleSplash')
      },
    },
  }
</script>
