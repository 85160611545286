<template>
  <div class="block--api-search grid-container">
    <div v-if="hasSearch" class="block__content">
      <div class="search">
        <form class="search__form" @submit.prevent>
          <input
            v-model.lazy="searchTerm"
            placeholder="search..."
            type="search"
            class="search__input" />
        </form>
      </div>
    </div>

    <div class="block__content">
      <!-- Loading State -->
      <div v-if="loading" class="results--loading-state">
        <div class="loading-state-container">
          <LoadingState></LoadingState>
        </div>
      </div>

      <!-- Search Results -->
      <div
        v-else-if="!loading && totalResults > 0"
        class="results--results-list">
        <div class="results__header">
          <div
            v-if="parsedFilters && parsedFilters.length"
            class="filter__content">
            <h2 v-if="filterTitle" class="filter__title">
              {{ filterTitle }}
            </h2>

            <select v-model="filterValue" class="filter__select">
              <option
                v-for="(option, i) in parsedFilters"
                :key="`filer-index--${i}`"
                :value="option">
                {{ option.text }}
              </option>
            </select>
          </div>

          <span class=""
            >({{ totalResults }})
            {{ totalResults == 1 ? 'Result' : 'Results' }}
          </span>
        </div>

        <div class="results__content">
          <ul class="results__list">
            <li
              v-for="(item, index) in results"
              :key="`list-item--${index}`"
              class="results__list-item">
              <ArticleItem :b="item"></ArticleItem>
            </li>
          </ul>

          <NavPaginate
            v-if="pagination"
            :pagination="pagination"
            @changePage="changePage"></NavPaginate>
        </div>
      </div>

      <!-- Fallback no results -->
      <div v-else class="results--fallback">
        <div class="redactor">
          <p>No results</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import ArticleItem from './ArticleItem.vue'
  import NavPaginate from './NavPaginate.vue'
  import LoadingState from './LoadingState.vue'

  export default {
    name: 'ApiSearch',
    components: { ArticleItem, NavPaginate, LoadingState },
    props: {
      hasSearch: {
        type: Boolean,
        default: false,
      },
      parsedTerm: {
        type: String,
        default: null,
      },
      parsedPage: {
        type: Number,
        default: 1,
      },
      parsedMode: {
        type: String,
        default: 'page',
      },
      parsedLimit: {
        type: Number,
        default: 10,
      },
      parsedFilters: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        loading: false,
        searchTerm: null,
        filterValue: null,
        query: {
          term: this.parsedTerm,
          page: this.parsedPage,
          mode: this.parsedMode,
          limit: this.parsedLimit,
          filter: this.parsedFilters.length
            ? this.parsedFilters[0].value
            : null,
        },
        response: null,
        results: [],
      }
    },
    computed: {
      filterTitle() {
        return this.filterValue && this.filterValue.text
          ? this.filterValue.text
          : 0
      },
      totalResults() {
        return this.results ? this.results.length : 0
      },
      pagination() {
        if (this.response && this.response.paginate) {
          return {
            firstPage: this.response.paginate.first_page,
            currentPage: this.response.paginate.current_page,
            totalPages: this.response.paginate.last_page,
          }
        }
        return { firstPage: 1, currentPage: 1, totalPages: 1 }
      },
    },
    watch: {
      searchTerm() {
        if (!this.loading && this.searchTerm !== this.query.term) {
          this.query.term = this.searchTerm
          this.query.page = 1
          this.getQueryResults()
        }
      },
      filterValue(e) {
        this.filterValue = e
        this.query.filter = e.value
        this.query.page = 1
        this.getQueryResults()
      },
    },
    mounted() {
      this.getQueryResults()
      this.$nextTick()
      this.filterValue =
        this.parsedFilters && this.parsedFilters[0]
          ? this.parsedFilters[0]
          : null
    },
    methods: {
      changePage(e) {
        this.query.page = e
        this.getQueryResults()
      },
      // Query results
      getQueryResults() {
        this.loading = true
        const self = this
        axios
          .get(
            `/api/search?noprotocol${
              self.query.term ? '&term=' + self.query.term : ''
            }${self.query.page ? '&page=' + self.query.page : ''}${
              self.query.mode ? '&mode=' + self.query.mode : ''
            }${
              self.query.filter
                ? '&filter=' + JSON.stringify(self.query.filter)
                : ''
            }${self.query.limit ? '&limit=' + self.query.limit : ''}`
          )
          .then((response) => {
            self.response = response.data ?? null
            self.results = response.data.results ?? null
            self.loading = false
          })
          .catch((error) => {
            self.loading = false
            console.error(error)
          })
      },
    },
  }
</script>
