<template>
  <div class="splash-screen" :class="{ 'splash-screen--open': open }">
    <div class="splash-content">
      <button class="close-button" @click="close()">Close menu</button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import EventBus from '../services/event-bus'

  export default {
    name: 'SplashScreen',
    data() {
      return {
        open: false,
      }
    },
    mounted() {
      EventBus.$on('toggleSplash', () => {
        this.open ^= true
      })
    },
    methods: {
      close() {
        EventBus.$emit('toggleSplash')
      },
    },
  }
</script>
