<template>
  <div v-if="selectDomain" class="langue-dropdowns__container">
    <!-- Domain dropdown -->
    <div class="dropdown__group langauge-dropdowns--desktop">
      <vSelect
        v-model="selectDomain"
        :options="isArray(domains)"
        :searchable="false"
        :placeholder="
          selectDomain && selectDomain.title ? selectDomain.title : 'Country'
        "
        :clearable="false"
        label="title"
        @open="disableLanguage = true"
        @close="disableLanguage = false"
        @input="changeDomain">
        <!-- Selected option -->
        <template slot="selected-option" slot-scope="option">
          <div class="option__text">
            <img
              :src="option.icon"
              class="flag"
              :class="`flag--${option.title}`"
              :alt="`flag-${option.title}`" />
            {{ option.title }}
          </div>
        </template>
        <!-- Options -->
        <template slot="option" slot-scope="option">
          <img
            :src="option.icon"
            class="flag"
            :class="`flag--${option.title}`"
            :alt="`flag-${option.title}`" />
          {{ option.title }}
        </template>
        <!-- Open Options -->
        <template slot="open-indicator">
          <svg
            class="button__toggle"
            viewBox="0 0 27 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z"
              fill="black" />
          </svg>
        </template>
      </vSelect>
    </div>

    <!-- Language dropdown -->
    <div class="dropdown__group langauge-dropdowns--desktop">
      <vSelect
        :id="selectDomain.id"
        v-model="selectLanguage"
        :options="isArray(selectDomain.languages)"
        :searchable="false"
        :placeholder="
          selectLanguage && selectLanguage.title
            ? selectLanguage.title
            : 'Language'
        "
        :clearable="false"
        :disabled="disableLanguage"
        label="title"
        @input="changeLanguage">
        <!-- Selected option -->
        <template slot="selected-option" slot-scope="option">
          <div class="option__text">
            <img
              :src="option.icon"
              class="flag"
              :class="`flag--${option.title}`"
              :alt="`flag-${option.title}`" />
            {{ option.title }}
          </div>
        </template>
        <!-- Options -->
        <template slot="option" slot-scope="option">
          <img
            :src="option.icon"
            class="flag"
            :class="`flag--${option.title}`"
            :alt="`flag-${option.title}`" />
          {{ option.title }}
        </template>
        <!-- Open Options -->
        <template slot="open-indicator">
          <svg
            class="button__toggle"
            viewBox="0 0 27 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z"
              fill="black" />
          </svg>
        </template>
      </vSelect>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  export default {
    name: 'LanguageDropdowns',
    components: { vSelect },
    props: {
      domains: {
        type: Array,
        default() {
          return []
        },
      },
      languages: {
        type: Array,
        default() {
          return []
        },
      },
      currentDomain: {
        type: Object,
        required: true,
      },
      currentLanguage: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectDomain: null,
        selectLanguage: null,
        disableLanguage: false,
      }
    },
    mounted() {
      this.setDefaultDomain()
      this.setDefaultLangauge()
    },
    methods: {
      log(e) {
        console.log(e)
      },
      isArray(e) {
        return Array.isArray(e) && e !== null ? e : []
      },
      changeDomain() {
        this.selectLanguage = null
      },
      changeLanguage(i) {
        if (this.selectLanguage && this.selectLanguage.url) {
          window.location.href = i.url
        }
      },
      setDefaultDomain() {
        const self = this
        const domainIndex = this.domains.filter((e) => {
          return e.id === self.currentDomain.id
        })
        this.selectDomain = domainIndex.length > 0 ? domainIndex[0] : []
      },
      setDefaultLangauge() {
        const self = this
        const languageIndex = this.languages.filter((e) => {
          return e.id === self.currentLanguage.id
        })
        this.selectLanguage = languageIndex.length > 0 ? languageIndex[0] : []
      },
    },
  }
</script>
