<template>
  <div v-if="evSearch" class="results--item">
    <div class="results__header">
      <h2 class="header__title">{{ evSearch.name }}</h2>
      <a
        v-if="evSearch.cta && evSearch.cta.url"
        class="header__link button primary"
        :href="evSearch.cta.url"
        :target="evSearch.cta.target ? evSearch.cta.target : null"
        >{{ evSearch.cta.text }}</a
      >
    </div>

    <div class="results__details results__connector">
      <div class="image-container">
        <picture class="image">
          <img
            :src="evSearch.connector_image.url"
            :alt="evSearch.connector_image.title" />
        </picture>
      </div>

      <h3 class="detail__title h2">{{ evSearch.connector }}</h3>
      <p class="detail__type">Connector</p>
    </div>

    <div class="results__details results__charger">
      <div class="image-container">
        <picture class="image">
          <img
            :src="evSearch.charger_image.url"
            :alt="evSearch.charger_image.title" />
        </picture>
      </div>
      <h3 class="detail__title">{{ evSearch.charger }}</h3>
      <p class="detail__type">Max charge speed</p>
    </div>

    <div
      class="results__description redactor"
      v-html="evSearch.description"></div>
  </div>
</template>

<script>
  export default {
    name: 'EvItem',
    props: {
      evSearch: {
        type: Object,
        required: true,
      },
    },
  }
</script>
