<style lang="scss" scoped></style>

<template>
  <div class="block article-overview grid-container">
    <div class="article-overview__content">
      <div v-if="title" class="article-overview__title">
        <slot :name="`title__decoration`"></slot>
        <h1 class="title extra-big--title">{{ title }}</h1>
      </div>

      <div class="article-overview__filter">
        <div class="dropdown__group article-overview__filter__years">
          <vSelect
            v-if="allowFilter"
            v-model="selectedFilter"
            :options="filters"
            :searchable="false"
            :clearable="false"
            label="text">
            <!-- Selected option -->
            <template slot="selected-option" slot-scope="option">
              <div class="option__text">{{ option.text }}</div>
            </template>
            <!-- Options -->
            <template slot="option" slot-scope="option">
              {{ option.text }}
            </template>
            <!-- Open Options -->
            <template slot="open-indicator">
              <svg
                class="button__toggle"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z"
                  fill="black" />
              </svg>
            </template>
          </vSelect>
        </div>
      </div>

      <!-- Search Results -->
      <div
        v-if="totalResults > 0"
        class="article-overview__results results--success">
        <ul class="results__list">
          <li
            v-for="(item, index) in results"
            :key="`list-item--${index}`"
            class="results__list__item"
            :class="[
              item.id
                ? 'results__list__item--article'
                : 'results__list__item--year',
            ]">
            <ArticleItem
              v-if="item.id"
              :b="item"
              :read-more-text="readMoreText"></ArticleItem>
            <div v-else class="results__list__year">
              <slot :name="`title__decoration`"></slot>
              <p class="title extra-big--title">{{ item.text }}</p>
            </div>
          </li>
        </ul>

        <div
          v-if="totalResults > amountShown"
          class="article-overview__list-button">
          <span class="button__line"></span>
          <button class="button" @click="showMoreArticles()">
            <svg
              class="button__label"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z"
                fill="#fbf1bf" />
            </svg>
          </button>
          <span class="button__line"></span>
        </div>
      </div>

      <!-- Search No-Results -->
      <div v-else class="article-overview__results results--fail">
        <div class="redactor">
          <p>No results</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // DOCS: https://vue-select.org/
  import axios from 'axios'
  import ArticleItem from './ArticleItem.vue'
  import vSelect from 'vue-select'

  export default {
    name: 'ArticleOverview',
    components: {
      vSelect,
      ArticleItem,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      filters: {
        type: Array,
        required: true,
      },
      allowFilter: {
        type: Boolean,
        default: false,
      },
      searchSettings: {
        type: Object,
        required: true,
      },
      readMoreText: {
        type: String,
        default: 'Load more',
      },
      showYearIndications: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        selectedFilter: {
          text: 'All years',
          value: null,
        },
        query: {},
        results: [],
        amountShown: 0,
        totalResults: 0,
        year: null,
      }
    },
    watch: {
      selectedFilter() {
        if (!this.loading) {
          this.resetSearch()
        }
      },
    },
    mounted() {
      this.query = {
        term: this.searchSettings.term ?? null,
        page: this.searchSettings.page ?? 1,
        mode: this.searchSettings.mode ?? 'blog',
        limit: this.searchSettings.limit ?? 10,
        filter: this.selectedFilter.value ?? null,
      }
      this.loading = false
      this.resetSearch()
    },
    methods: {
      resetSearch() {
        this.results = []
        this.totalResults = 0
        this.amountShown = this.query.limit
        this.query.filter = this.selectedFilter.value ?? null
        this.year = null
        this.getQueryResults()
      },
      showMoreArticles() {
        this.amountShown += this.query.limit
        this.query.page++
        this.getQueryResults()
      },
      // Query results
      getQueryResults() {
        if (!this.loading && this.query.page >= 1) {
          this.loading = true
          const self = this
          console.log(self.query.filter)
          axios
            .get(
              `/api/search?fastned${
                self.query.page ? '&page=' + self.query.page : ''
              }${self.query.mode ? '&mode=' + self.query.mode : ''}${
                self.query.filter
                  ? '&filter=' + JSON.stringify(self.query.filter)
                  : ''
              }${self.query.limit ? '&limit=' + self.query.limit : ''}`
            )
            .then(function (response) {
              self.results = self.results.concat(
                self.addYearsToList(response.data.results)
              )
              self.totalResults = response.data.totalCount
              self.loading = false
            })
            .catch(function () {
              self.loading = false
            })
        }
      },
      addYearsToList(results) {
        const self = this
        this.year = Number(this.year)
        const structuredResults = []
        results.forEach((e) => {
          const year = Number(e.year)
          if (year && year !== self.year && self.showYearIndications) {
            self.year = year
            structuredResults.push({ text: year })
          }
          structuredResults.push(e)
        })
        return structuredResults
      },
    },
  }
</script>
