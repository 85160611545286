<template>
  <div class="content-block__content">
    <h2 v-if="title" class="block__title">{{ title }}</h2>
    <ul v-if="faqData" class="faq-block">
      <li
        v-for="(i, index) in faqData"
        :key="`faq-item--${index}`"
        class="faq-item">
        <button
          class="faq-item__question"
          :class="[{ 'question--open': openFaq === index }]"
          @click="toggleFaq(index)">
          <h3 class="item__title">
            {{ i.question }}
          </h3>
          <slot name="chevron"></slot>
        </button>
        <transition v-if="openFaq === index">
          <div class="faq-item__answer redactor" v-html="i.answer"></div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'FaqBlock',
    props: {
      title: {
        type: String,
        default: null,
      },
      faqData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        openFaq: null,
      }
    },
    methods: {
      toggleFaq(i) {
        this.openFaq = this.openFaq === i ? null : i
      },
    },
  }
</script>
